import React from "react";

function requestTypeIntro(props) {
  const { requestType, id } = props;

  let text = "";

  switch (requestType) {
    case "access":
      text =
        "Please fill out the below Data Subject Request (DSR) form and describe your request. This form is directed to residents who have certain rights as data subjects in relation to their personal data under some state or national laws. We respect the privacy and security of your personal information. To keep your information secure, we will only send you information that we can reasonably link to the email address you provide in this form.";
      break;
    case "delete":
      text =
        "Please fill out the below Data Subject Request (DSR) form and describe your request. This form is directed to residents who have certain rights as data subjects in relation to their personal data under some state or national laws. We respect the privacy and security of your personal information. To keep your information secure, we will only delete information that we can reasonably link to the email address you provide in this form.";
      break;
    case "other":
      text =
        "Please fill out the below Data Subject Request (DSR) form and describe your request. This form is directed to residents who have certain rights as data subjects in relation to their personal data under some state or national laws.";
      break;

    default:
      break;
  }

  return (
    <div>
      <section className="container">
        <p className="text-justify mt-3">{text}</p>
        {id === "USA" ? (
          <p>
            <strong>Note for Clinical Trial subjects:</strong> Data collected in
            clinical trials is not subject to the California Consumer Privacy
            Act (see §1798.145(c)(1)(C) of the California Civil Code). For your
            privacy, we receive trial data in pseudo-anonymized form, which
            means that we do not know participant names. Please contact the site
            location for information related to your participation in a trial
            sponsored by Seagen.
          </p>
        ) : (
          <p>
            <strong>Note for Clinical Trial subjects:</strong> For your privacy,
            we receive trial data in pseudo-anonymized form, which means that we
            do not know participant names. Please contact the site location for
            information related to your participation in a trial sponsored by
            Seagen.
          </p>
        )}
      </section>
    </div>
  );
}

export default requestTypeIntro;
