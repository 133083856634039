import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Recaptcha from "./ReCaptcha";
import { useForm, Controller } from "react-hook-form";
import { textValidation, emailValidation } from "./Validation";
import getPayload from "./getPayload";
import { getService } from "../../shared/components/restApi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function RequestForm(props) {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [sendRequest, setSendRequest] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [value, setValue] = useState("");
  const {
    configValues,
    enableLoader,
    getAcknowledgement,
    getSubmitRequestId,
    requestType,
  } = props;

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  const getResponseToken = (token) => {
    setRecaptchaToken(token);
  };

  const { register, handleSubmit, formState, errors, validate, control } =
    useForm({
      mode: "onChange",
    });

  const onSubmit = (data) => {
    // submitted Data
    setSendRequest(data);
  };

  useEffect(() => {
    if (sendRequest) {
      // activate the loader
      enableLoader(true);

      // payload
      const inputsForPayLoad = {
        request: requestType,
        inputsData: sendRequest,
        configValues,
        recaptchaToken,
      };

      const payload = getPayload(inputsForPayLoad);

      // API request

      const url = process.env.REACT_APP_CURRENT_ENVIRONMENT_URL;

      const submitOptions = {
        method: `POST`,
        url,
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            getSubmitRequestId(response.data.rootId);
          }
        })
        .then(() => {
          // deactivate the loader and enable the Acknowledgement
          enableLoader(false);
          getAcknowledgement(true);
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
    return () => {
      setSendRequest(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  return (
    <div className="main-content">
      <Card className="my-5 mx-auto form shadow">
        <Card.Body>
          <div className="form-title mb-3">
            <Card.Text>
              <strong>
                Please provide the following information to start your request:
              </strong>
            </Card.Text>
            <Card.Text>
              <span className="text-danger">*</span> Required fields
            </Card.Text>
          </div>
          <Form className="form-inputs" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3 w-75">
              <Form.Row>
                <Form.Label className="fw-bold">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email *"
                  className={!errors.email ? "mb-2" : "error-border mb-2"}
                  ref={register(emailValidation)}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </Form.Row>

              <Card.Text>
                We'll use this email address to reply to your request
              </Card.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    First and Last Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="First and Last Name *"
                    className={!errors.firstName ? "mb-2" : "error-border"}
                    ref={register(textValidation)}
                  />
                  {errors.name && (
                    <small className="text-danger">
                      {" "}
                      {errors.name.message}{" "}
                    </small>
                  )}
                </Col>

                <Col>
                  <Form.Label className="fw-bold">Phone Number</Form.Label>
                  <Controller
                    as={
                      <PhoneInput
                        value={value}
                        onChange={(value) => setValue(value)}
                        inputProps={{
                          name: "phoneNumber",
                          required: false,
                        }}
                        ref={() => {
                          register({ required: false });
                        }}
                        name="phoneNumber"
                        enableSearch
                        placeholder="Phone Number"
                        buttonClass="rounded "
                      />
                    }
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: {
                        value: /^([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g,
                        message: "A valid number is required",
                      },
                      minLength: {
                        value: 11,
                        message: "A valid number is required",
                      },
                      maxLength: {
                        value: 14,
                        message: "A valid number is required",
                      },
                    }}
                  />
                  {errors.phoneNumber && (
                    <small className="text-danger">
                      {" "}
                      {errors.phoneNumber.message}{" "}
                    </small>
                  )}
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    Relationship Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Row className="ml-2">
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Candidate for employment at Seagen"
                        name="defineYourRelationshipWithSeagen"
                        id="newEmployee"
                        value="Candidate for employment at Seagen"
                        className="left radio-label-item white-space-nowrap"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Former employee of Seagen"
                        name="defineYourRelationshipWithSeagen"
                        id="formerEmployee"
                        value="Former employee of Seagen"
                        className="text-center radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Employee of Seagen"
                        name="defineYourRelationshipWithSeagen"
                        value="Employee of Seagen"
                        id="currentEmployee"
                        className="left radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Contractor"
                        name="defineYourRelationshipWithSeagen"
                        value="Contractor"
                        id="contractor"
                        className="mr-5 radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Healthcare Professional"
                        name="defineYourRelationshipWithSeagen"
                        value="Healthcare Professional"
                        id="healthProfessional"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Patient/user of our products"
                        name="defineYourRelationshipWithSeagen"
                        value="Patient/user of our products"
                        id="patientOrUser"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Clinical Trial Participant"
                        name="defineYourRelationshipWithSeagen"
                        value="Clinical Trial Participant"
                        id="clinicalTrial"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Web site user"
                        name="defineYourRelationshipWithSeagen"
                        value="Web site user"
                        id="websiteUser"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="On site visitor"
                        name="defineYourRelationshipWithSeagen"
                        value="On site visitor"
                        id="onsiteUser"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Recipient of marketing material"
                        name="defineYourRelationshipWithSeagen"
                        value="Recipient of marketing material"
                        id="marketingMaterialRecipient"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Reporter of adverse event"
                        name="defineYourRelationshipWithSeagen"
                        value="Reporter of adverse event"
                        id="adverseEventReporter"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Reporter of product complaint"
                        name="defineYourRelationshipWithSeagen"
                        value="Reporter of product complaint"
                        id="productComplaintReporter"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Medical Information requester"
                        name="defineYourRelationshipWithSeagen"
                        value="Medical Information requester"
                        id="medicalInformation"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Employee/agent of a company that does, did, or has attempted to do business with Seagen"
                        name="defineYourRelationshipWithSeagen"
                        value="Employee/agent of a company that does, did, or has attempted to do business with Seagen"
                        id="employee/agent"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check
                        type="radio"
                        inline
                        label="Other (please provide details in Additional Information field)"
                        name="defineYourRelationshipWithSeagen"
                        value="Other (please provide details in Additional Information field)"
                        id="other"
                        className="right radio-label-item"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            {requestType === "category-access" ? (
              <Form.Group className="mb-3 w-100">
                <Row>
                  <Col>
                    <Form.Label className="fw-bold">
                      Please provide additional information to clarify request
                      scope <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="additionalInformation"
                      placeholder="Additional Information"
                      className={
                        !errors.additionalInformation ? "mb-2" : "error-border"
                      }
                      ref={register({ required: true })}
                    />
                    {errors.additionalInformation && (
                      <small className="text-danger">
                        {" "}
                        {errors.additionalInformation.message}{" "}
                      </small>
                    )}
                  </Col>
                </Row>
              </Form.Group>
            ) : (
              <>
                <Form.Group className="mb-3 w-75">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold">
                        Additional Information
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="additionalInfo"
                        placeholder="Additional Information"
                        className={
                          !errors.additionalInformation
                            ? "mb-2"
                            : "error-border"
                        }
                        ref={register({ required: false })}
                      />
                      {errors.additionalInformation && (
                        <small className="text-danger">
                          {" "}
                          {errors.additionalInformation.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </>
            )}

            <div className="d-flex justify-content-center mt-4">
              <Recaptcha
                reCaptchaClick={handleReCaptchaClick}
                responseToken={getResponseToken}
              />
            </div>

            <Form.Group>
              <Row className="d-flex justify-content-center mt-5">
                <Col sm={12} md={10} lg={10} className="text-center">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    style={{
                      width: "inherit",
                    }}
                    disabled={!formState.isValid || disableSubmit || validate}
                  >
                    Submit Request
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RequestForm;
