import React, { useState } from "react";
import { Container, Button, DropdownButton, Dropdown } from "react-bootstrap";
import SrrHeader from "../shared/components/SrrHeader";
import { Link } from "react-router-dom";
export default SubmitRequest;

function SubmitRequest() {
  const [USAActive, setUSAActive] = useState(false);
  const [EUActive, setEUActive] = useState(false);

  const [dropdownValue, setDropdownValue] = useState("Select Region");
  const [isResident, setIsResident] = useState("");

  const handleClick = (e) => {
    if (e.target.innerText === "USA") {
      setUSAActive(true);
      setEUActive(false);
      setDropdownValue("USA");
    } else if (e.target.innerText === "EU") {
      setUSAActive(false);
      setEUActive(true);
      setDropdownValue("EU");
    }
  };
  return (
    <>
      <SrrHeader
        heading="Submit Data Subject Request"
        breadcrumb_path1="Seagen Trust Center"
        breadcrumb_path2="SUBMIT DATA SUBJECT REQUEST"
      />

      <Container className="mt-4">
        <div className="mb-3 mt-4">
          <p>
            Seagen is committed to the responsible collection and use of
            personal information. If your request is to access, obtain copies
            of, correct, or erase your personal data, or to limit the transfer
            of your data to certain 3rd parties, we may need to contact you and
            request additional information to verify your identity before
            processing your request.
          </p>
          <p>
            <strong>Verification of identity:</strong> This form requests
            information that we will use to verify that you are who you say you
            are. We may require documentation, including evidence of your
            authority to act on someone else's behalf, if applicable. Any
            documentation you provide will be destroyed or deleted after it is
            no longer needed and our response to you is completed.
          </p>
          <p>
            <strong>Record Retention:</strong> We will retain personal data
            related to your request for 3 years after your request has been
            fulfilled. In the event of actual or potential legal proceedings in
            which your request is relevant, we may retain your personal data
            until the proceedings have resolved and the data is no longer
            needed.
          </p>
          <p>
            To initiate your request, please complete the following e-form
            below.
          </p>
        </div>
        <section>
          <p>
            <strong>Please select region of residence</strong>{" "}
            <span className="text-danger">*</span>
          </p>

          <DropdownButton
            id="dropdown-basic-button"
            title={dropdownValue}
            className="mb-4"
          >
            <Dropdown.Item onClick={handleClick}>USA</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>EU</Dropdown.Item>
          </DropdownButton>
        </section>

        {USAActive && (
          <>
            <div
              id="caResident"
              className="mb-3 mt-4"
              style={
                isResident.length > 0
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <p>
                Because the CCPA only provides these choices to California
                residents, Seagen will only fulfill requests submitted by
                California residents.
              </p>

              <p>
                <strong>Note for Clinical Trial subjects:</strong> Data
                collected in clinical trials is not subject to the California
                Consumer Privacy Act (see §1798.145(c)(1)(C) of the California
                Civil Code). For your privacy, we receive trial data in
                pseudo-anonymized form, which means that we do not know
                participant names. Please contact the site location for
                information related to your participation in a trial sponsored
                by Seagen.
              </p>

              <p>
                <strong>I am a California resident</strong>{" "}
                <span className="text-danger">*</span>
              </p>

              <form>
                <div className="form-check cursor">
                  <label className="form-check-label cursor">
                    <input
                      type="radio"
                      id="yes"
                      className="form-check-input text-success cursor"
                      name="isResident"
                      onClick={() => setIsResident("Yes")}
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check cursor">
                  <label className="form-check-label cursor">
                    <input
                      type="radio"
                      id="no"
                      className="form-check-input text-success cursor"
                      name="isResident"
                      onClick={() => setIsResident("No")}
                    />
                    No
                  </label>
                </div>
              </form>
            </div>
            {isResident === "" ? (
              ""
            ) : isResident === "No" ? (
              <section
                style={{ display: "block" }}
                className="mt-4 submit-request"
              >
                <p>
                  <strong>
                    The CCPA extends privacy rights to California residents
                    only.
                  </strong>
                </p>
                <p>
                  You have indicated that you are not a California resident. As
                  a financial institution and insurance company, please be
                  assured that all customer data collected, processed, stored or
                  disclosed by Seagen is subject to and protected by the
                  Gramm-Leach-Bliley Act (GLBA), the Health Insurance
                  Portability and Accountability Act (HIPAA), and other
                  applicable state privacy and data security laws. At Seagen, we
                  value your privacy and consider the protection of your
                  personal information critically important.
                </p>
                <p>
                  If you would like to learn more about how Seagen may collect,
                  use, and share your personal information, please visit our{" "}
                  <u>
                    <a
                      href="https://www.seagen.com/privacy-policies"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </u>
                  .
                </p>
              </section>
            ) : (
              <section id="usa" className="mb-5">
                <p>
                  If you are a California resident, you may make certain
                  requests regarding your personal information. We will fulfill
                  each of these requests to the extent required by law.
                </p>
                <p>
                  <strong>Note for Clinical Trial subjects:</strong> Data
                  collected in clinical trials is not subject to the California
                  Consumer Privacy Act (see §1798.145(c)(1)(C) of the California
                  Civil Code). For your privacy, we receive trial data in
                  pseudo-anonymized form, which means that we do not know
                  participant names. Please contact the site location for
                  information related to your participation in a trial sponsored
                  by Seagen.
                </p>
                <p>
                  <strong>Access</strong> - Please select the “Access” button
                  below to request information about the categories and specific
                  pieces of personal information Seagen has collected about you,
                  the categories of sources from which such information is
                  collected, the purpose for collecting such information, and/or
                  the categories of third parties with whom we share such
                  information.
                </p>
                <p>
                  <strong>Delete</strong> - Please select the “Delete” button
                  below to request that Seagen delete your personal information
                  from its systems.
                </p>
                <p>
                  <strong>Other</strong> - Please select the “Other” button
                  below to reach out to us for any other requests
                </p>

                <section id="myselfRequests" className="mt-4">
                  <p>
                    <u>
                      Please select the type of request you would like to submit
                    </u>
                  </p>
                  <div className="list d-flex flex-sm-row flex-column">
                    <div className="me-3">
                      <Link
                        className="selfLink"
                        to={`USA/Access`}
                        target="_blank"
                      >
                        <Button variant="primary"> Access </Button>
                      </Link>
                    </div>
                    <div className="me-3 mt-3 mt-sm-0">
                      <Link
                        className="selfLink"
                        to={`USA/Delete`}
                        target="_blank"
                      >
                        <Button variant="primary" className="list-button">
                          {" "}
                          Delete{" "}
                        </Button>
                      </Link>
                    </div>
                    <div className="me-3 mt-3 mt-sm-0">
                      <Link
                        className="selfLink"
                        to={`USA/Other`}
                        target="_blank"
                      >
                        <Button variant="primary" className="list-button">
                          {" "}
                          Other{" "}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </section>
              </section>
            )}
          </>
        )}

        {EUActive && (
          <section id="eu" className="mb-5">
            <p>
              If you are a EU resident, you may make certain requests regarding
              your personal information. We will fulfill each of these requests
              pursuant to the requirements of applicable law.
            </p>

            <p>
              <strong>Note for Clinical Trial subjects:</strong> For your
              privacy, we receive trial data in pseudo-anonymized form, which
              means that we do not know participant names. Please contact the
              site location for information related to your participation in a
              trial sponsored by Seagen.
            </p>

            <p>
              <strong>Access</strong> - Please select the Access button below to
              request that Seagen provide you with confirmation as to whether we
              process your personal data. In the event that we do process your
              personal data, we will provide you with (i) access to the personal
              data, (ii) the purpose of processing, (iii) the categories of
              personal data concerned, (iv) the recipients or categories of
              recipients to whom the personal data have been or will be
              disclosed, (v) where possible, the envisaged period for which the
              personal data will be stored, or, if not possible, the criteria
              used to determine that period, (vi) where the personal data are
              not collected from you, any available information as to their
              source, and (vii) the existence of any automated decision-making,
              such as profiling, and meaningful information about the logic
              involved. Please be sure to specifically articulate any requests
              you may have for Seagen to transmit your personal data to another
              organization pursuant to your data access right.
            </p>
            <p>
              <strong>Delete</strong> - Please select the “Delete” button below
              to request that Seagen erase your personal data in accordance with
              applicable law.
            </p>
            <p>
              <strong>Other</strong> - Please select the “Other” button below to
              reach out to us for any other requests
            </p>

            <section id="myselfRequests" className="mt-4">
              <p>
                <u>
                  Please select the type of request you would like to submit
                </u>
              </p>
              <div className="list d-flex flex-sm-row flex-column">
                <div className="me-3">
                  <Link className="selfLink" to={`EU/Access`} target="_blank">
                    <Button variant="primary"> Access </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`EU/Delete`} target="_blank">
                    <Button variant="primary"> Delete </Button>
                  </Link>
                </div>

                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`EU/Other`} target="_blank">
                    <Button variant="primary" className="list-button">
                      {" "}
                      Other{" "}
                    </Button>
                  </Link>
                </div>
              </div>
            </section>
          </section>
        )}
      </Container>
    </>
  );
}
